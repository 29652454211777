
import { defineComponent, PropType } from "vue";

import { Input } from "@/components/common";
import { ISection } from "@/types";

export default defineComponent({
  components: {
    Input,
  },
  props: {
    attribute: {
      required: true,
      type: String,
    },
    selectedComponent: {
      required: true,
      type: Object as PropType<ISection>,
    },
  },
  computed: {
    textAttribute() {
      return this.selectedComponent.attributes[this.attribute];
    },
  },
  methods: {
    updateText(e: { target: HTMLInputElement }) {
      const newAttr = { [this.attribute]: e.target.value };

      this.$store.dispatch("updateAttributes", {
        id: this.selectedComponent.id,
        attributes: newAttr,
      });
    },
  },
});
