import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "attribute-value" }
const _hoisted_2 = ["htmlFor"]
const _hoisted_3 = ["checked", "id"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_CheckboxWrapper = _resolveComponent("CheckboxWrapper")!

  return (_openBlock(), _createBlock(_component_CheckboxWrapper, null, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("label", { htmlFor: _ctx.settingName }, _toDisplayString(_ctx.settingName), 9, _hoisted_2),
        _createElementVNode("input", {
          onChange: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.updateValue && _ctx.updateValue(...args))),
          checked: _ctx.selectedComponent.attributes[_ctx.attribute],
          id: _ctx.settingName,
          type: "checkbox"
        }, null, 40, _hoisted_3)
      ])
    ]),
    _: 1
  }))
}