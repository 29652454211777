
import { defineComponent, PropType } from "vue";

import { Divider } from "@/components/common";
import { ISection } from "@/types";

import { TextBlockContent, TextBlockWrapper } from "./style";

export default defineComponent({
  components: {
    Divider,
    TextBlockContent,
    TextBlockWrapper,
  },
  props: {
    isInBuilder: {
      default: false,
      type: Boolean,
    },
    section: {
      type: Object as PropType<ISection>,
    },
  },
});
