
import { defineComponent, PropType } from "vue";

import { Dictionary, ISection } from "@/types";

import { CheckboxWrapper } from "./style";

export default defineComponent({
  components: {
    CheckboxWrapper,
  },
  props: {
    attribute: {
      required: true,
      type: String,
    },
    selectedComponent: {
      required: true,
      type: Object as PropType<ISection>,
    },
  },
  data() {
    return {
      name: {
        hasColGap: "Column gap",
        hasSectionGap: "Section gap",
        reversed: "Reversed layout",
      } as Dictionary<string>,
    };
  },
  computed: {
    settingName() {
      return this.name[this.attribute];
    },
  },
  methods: {
    updateValue() {
      const newValue = !this.selectedComponent.attributes[this.attribute];
      const newAttr = { [this.attribute]: newValue };

      this.$store.dispatch("updateAttributes", {
        id: this.selectedComponent.id,
        attributes: newAttr,
      });
    },
  },
});
