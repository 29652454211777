import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-a20eb83a"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "text-wrapper" }
const _hoisted_2 = { class: "attribute-name" }
const _hoisted_3 = { class: "attribute-value" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Input = _resolveComponent("Input")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, _toDisplayString(_ctx.attribute), 1),
    _createElementVNode("div", _hoisted_3, [
      _createVNode(_component_Input, {
        name: _ctx.attribute,
        onChange: _ctx.updateText,
        placeholder: `Enter a ${_ctx.attribute}`,
        value: _ctx.textAttribute
      }, null, 8, ["name", "onChange", "placeholder", "value"])
    ])
  ]))
}