import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = ["alt", "src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_GalleryWrapper = _resolveComponent("GalleryWrapper")!

  return (_openBlock(), _createBlock(_component_GalleryWrapper, {
    id: _ctx.section.id,
    isInBuilder: _ctx.isInBuilder,
    section: _ctx.section
  }, {
    default: _withCtx(() => [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.imagesArray, (image) => {
        return (_openBlock(), _createElementBlock("img", {
          alt: image,
          class: _normalizeClass(`img-${_ctx.section.attributes.columns}-col`),
          key: image,
          src: require(`@/assets/builder/${image}.jpg`)
        }, null, 10, _hoisted_1))
      }), 128))
    ]),
    _: 1
  }, 8, ["id", "isInBuilder", "section"]))
}