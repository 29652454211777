import { resolveComponent as _resolveComponent, createVNode as _createVNode, withModifiers as _withModifiers, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-600f685e"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "actions-wrapper" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_IconRemove = _resolveComponent("IconRemove")!
  const _component_IconSettings = _resolveComponent("IconSettings")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", {
      class: "action-item",
      onClick: _cache[0] || (_cache[0] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.removeComponent && _ctx.removeComponent(...args)), ["stop"]))
    }, [
      _createVNode(_component_IconRemove)
    ]),
    _createElementVNode("div", {
      class: "action-item",
      onClick: _cache[1] || (_cache[1] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.handleSelect && _ctx.handleSelect(...args)), ["stop"]))
    }, [
      _createVNode(_component_IconSettings)
    ])
  ]))
}