
import { defineComponent, PropType } from "vue";

import { Button } from "@/components/common";
import { ISection } from "@/types";

import { HeroWrapper } from "./style";

export default defineComponent({
  components: {
    Button,
    HeroWrapper,
  },
  props: {
    isInBuilder: {
      default: false,
      type: Boolean,
    },
    section: {
      type: Object as PropType<ISection>,
    },
  },
});
