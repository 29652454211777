
import { defineComponent } from "vue";

import { Item, ProdMenuWrapper } from "./style";

export default defineComponent({
  components: {
    Item,
    ProdMenuWrapper,
  },
  computed: {
    projectName() {
      return this.$store.getters.getProjectName;
    },
    transparentMenu() {
      return this.$store.getters.getTransparentMenu;
    },
  },
});
