import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "attribute-name" }
const _hoisted_2 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_CircleWrapper = _resolveComponent("CircleWrapper")!
  const _component_AlignmentWrapper = _resolveComponent("AlignmentWrapper")!

  return (_openBlock(), _createBlock(_component_AlignmentWrapper, null, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, _toDisplayString(_ctx.attribute) + " :", 1),
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.alignments, (justify) => {
        return (_openBlock(), _createElementBlock("div", {
          class: _normalizeClass(`row ${justify}`),
          key: justify
        }, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.alignments, (align) => {
            return (_openBlock(), _createElementBlock("div", { key: align }, [
              _createElementVNode("div", {
                class: _normalizeClass(`box ${align}`),
                onClick: ($event: any) => (_ctx.updateAlignment(align, justify))
              }, [
                _createVNode(_component_CircleWrapper, {
                  class: "circle",
                  isSelected: 
              _ctx.componentAlignments.alignItems === align &&
              _ctx.componentAlignments.justifyContent === justify
            
                }, null, 8, ["isSelected"])
              ], 10, _hoisted_2)
            ]))
          }), 128))
        ], 2))
      }), 128))
    ]),
    _: 1
  }))
}