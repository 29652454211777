import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-28aabb32"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "input-wrapper" }
const _hoisted_2 = ["name", "placeholder", "type", "value"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("input", {
      onInput: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.onChange && _ctx.onChange(...args))),
      name: _ctx.name,
      placeholder: _ctx.placeholder,
      type: _ctx.type,
      value: _ctx.value
    }, null, 40, _hoisted_2)
  ]))
}