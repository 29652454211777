
import { defineComponent } from "vue";

import { getDocumentFromLocalStorage } from "@/utils/localStorage";

import GoBackButton from "./GoBackButton.vue";

export default defineComponent({
  components: {
    GoBackButton,
  },
  computed: {
    hasSections() {
      return getDocumentFromLocalStorage().length > 0;
    },
    projectFont() {
      return this.$store.getters.getProjectFont;
    },
    sections() {
      return getDocumentFromLocalStorage();
    },
    showGoBackButton() {
      return window.location.pathname === "/prod";
    },
  },
});
