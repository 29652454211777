
import { defineComponent } from "vue";

export default defineComponent({
  props: {
    name: {
      type: String,
    },
    onChange: {
      type: Function,
    },
    placeholder: {
      type: String,
    },
    type: {
      default: "text",
      type: String,
    },
    value: {
      type: String,
    },
  },
});
