import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-11404a45"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "select-wrapper" }
const _hoisted_2 = ["id", "name", "value"]
const _hoisted_3 = { value: "" }
const _hoisted_4 = ["value"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("select", {
      onChange: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.onChange && _ctx.onChange(...args))),
      id: _ctx.name,
      name: _ctx.name,
      value: _ctx.value
    }, [
      _createElementVNode("option", _hoisted_3, _toDisplayString(_ctx.placeholder), 1),
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.options, (option) => {
        return (_openBlock(), _createElementBlock("option", {
          key: option,
          value: option
        }, _toDisplayString(option), 9, _hoisted_4))
      }), 128))
    ], 40, _hoisted_2)
  ]))
}