import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeStyle as _normalizeStyle, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "content" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Button = _resolveComponent("Button")!
  const _component_HeroWrapper = _resolveComponent("HeroWrapper")!

  return (_openBlock(), _createBlock(_component_HeroWrapper, {
    id: _ctx.section.id,
    isInBuilder: _ctx.isInBuilder,
    section: _ctx.section,
    style: _normalizeStyle({
      backgroundImage: 'url(' + require('@/assets/builder/22.jpg') + ')',
    })
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("h1", null, _toDisplayString(_ctx.section.attributes.title), 1),
        _createElementVNode("p", null, _toDisplayString(_ctx.section.attributes.subtitle), 1),
        _createVNode(_component_Button, {
          color: _ctx.section.attributes.buttonColor,
          text: "Discover"
        }, null, 8, ["color"])
      ])
    ]),
    _: 1
  }, 8, ["id", "isInBuilder", "section", "style"]))
}