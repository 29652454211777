import { resolveComponent as _resolveComponent, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveDynamicComponent as _resolveDynamicComponent, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeStyle as _normalizeStyle, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-01e3905d"),n=n(),_popScopeId(),n)
const _hoisted_1 = { key: 0 }
const _hoisted_2 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_prod_menu = _resolveComponent("prod-menu")!
  const _component_GoBackButton = _resolveComponent("GoBackButton")!

  return (_openBlock(), _createElementBlock("div", {
    class: "prod-wrapper",
    style: _normalizeStyle({
      'font-family': _ctx.projectFont,
    })
  }, [
    _createVNode(_component_prod_menu),
    (_ctx.hasSections)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.sections, (section) => {
            return (_openBlock(), _createElementBlock("div", {
              key: section.id
            }, [
              (_openBlock(), _createBlock(_resolveDynamicComponent(section.componentName), {
                isInBuilder: false,
                section: section
              }, null, 8, ["section"]))
            ]))
          }), 128))
        ]))
      : (_openBlock(), _createElementBlock("h3", _hoisted_2, "Create your page in the builder.")),
    (_ctx.showGoBackButton)
      ? (_openBlock(), _createBlock(_component_GoBackButton, { key: 2 }))
      : _createCommentVNode("", true)
  ], 4))
}