
import { defineComponent } from "vue";

export default defineComponent({
  props: {
    color: {
      default: "gray",
      type: String,
    },
  },
});
