
import { defineComponent, PropType } from "vue";

import { IconBack } from "@/components/common";
import {
  Alignment,
  Checkbox,
  Color,
  Height,
  SelectNumber,
  Text,
} from "@/components/Settings";
import { ISection } from "@/types";

import {
  CHECKBOX_SETTINGS,
  COLOR_SETTINGS,
  NUMBER_SETTINGS,
  TEXT_SETTINGS,
} from "../utils";

export default defineComponent({
  components: {
    Alignment,
    Checkbox,
    Color,
    Height,
    IconBack,
    SelectNumber,
    Text,
  },
  props: {
    selectedComponent: {
      required: true,
      type: Object as PropType<ISection>,
    },
  },
  computed: {
    attributesList() {
      return Object.keys(this.selectedComponent.attributes);
    },
    checkboxSettings() {
      return CHECKBOX_SETTINGS;
    },
    colorSettings() {
      return COLOR_SETTINGS;
    },
    numberSettings() {
      return NUMBER_SETTINGS;
    },
    textSettings() {
      return TEXT_SETTINGS;
    },
  },
  methods: {
    goBack() {
      this.$store.dispatch("selectComponent", null);
    },
  },
});
