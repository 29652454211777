import { resolveComponent as _resolveComponent, createVNode as _createVNode, resolveDynamicComponent as _resolveDynamicComponent, openBlock as _openBlock, createBlock as _createBlock, withCtx as _withCtx, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, withModifiers as _withModifiers, normalizeStyle as _normalizeStyle, Fragment as _Fragment, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-af73a5b6"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "builder-wrapper" }
const _hoisted_2 = {
  key: 0,
  class: "playground-wrapper"
}
const _hoisted_3 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_TopBar = _resolveComponent("TopBar")!
  const _component_Sidebar = _resolveComponent("Sidebar")!
  const _component_ProdMenu = _resolveComponent("ProdMenu")!
  const _component_Resize = _resolveComponent("Resize")!
  const _component_draggable = _resolveComponent("draggable")!
  const _component_Placeholder = _resolveComponent("Placeholder")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_TopBar),
    _createElementVNode("div", _hoisted_1, [
      _createVNode(_component_Sidebar),
      _createElementVNode("div", {
        class: "drop-zone",
        onDrop: _cache[1] || (_cache[1] = ($event: any) => (_ctx.onDrop($event))),
        onDragover: _cache[2] || (_cache[2] = _withModifiers(() => {}, ["prevent"])),
        onDragenter: _cache[3] || (_cache[3] = _withModifiers(() => {}, ["prevent"])),
        style: _normalizeStyle({
        'font-family': _ctx.projectFont,
      })
      }, [
        (_ctx.hasSections)
          ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
              _createVNode(_component_ProdMenu),
              _createVNode(_component_draggable, {
                list: _ctx.sections,
                disabled: !_ctx.enabled,
                "item-key": "id",
                class: "playground-container",
                "ghost-class": "ghost",
                onStart: _cache[0] || (_cache[0] = ($event: any) => (_ctx.dragging = true)),
                onEnd: _ctx.onDragEnd
              }, {
                item: _withCtx(({ element }) => [
                  _createElementVNode("div", {
                    class: _normalizeClass(["list-group-item", { 'not-draggable': !_ctx.enabled }])
                  }, [
                    _createVNode(_component_Resize, { section: element }, {
                      default: _withCtx(() => [
                        (_openBlock(), _createBlock(_resolveDynamicComponent(element.componentName), {
                          isInBuilder: "",
                          section: element
                        }, null, 8, ["section"]))
                      ]),
                      _: 2
                    }, 1032, ["section"])
                  ], 2)
                ]),
                _: 1
              }, 8, ["list", "disabled", "onEnd"]),
              (!_ctx.selectedComponent && !_ctx.draggedOverComponent)
                ? (_openBlock(), _createBlock(_component_Placeholder, { key: 0 }))
                : _createCommentVNode("", true)
            ]))
          : (_openBlock(), _createElementBlock("h3", _hoisted_3, "Add your first element."))
      ], 36)
    ])
  ], 64))
}