
import { defineComponent } from "vue";

import Prod from "@/pages/Prod/index.vue";

export default defineComponent({
  components: {
    Prod,
  },
  props: {
    onClose: {
      type: Function,
    },
  },
  created() {
    const body = document.querySelector("body");
    body?.setAttribute("id", "force-mobile");
  },
  unmounted() {
    const body = document.querySelector("body");
    body?.removeAttribute("id");
  },
});
