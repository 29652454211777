import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, normalizeStyle as _normalizeStyle, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = ["alt", "src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_PartnersWrapper = _resolveComponent("PartnersWrapper")!

  return (_openBlock(), _createBlock(_component_PartnersWrapper, {
    id: _ctx.section.id,
    isInBuilder: _ctx.isInBuilder,
    section: _ctx.section
  }, {
    default: _withCtx(() => [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.partnersArray, (partner) => {
        return (_openBlock(), _createElementBlock("div", {
          class: "image-wrapper",
          key: partner,
          style: _normalizeStyle({
        width: `calc(100% / ${_ctx.section.attributes.itemCount})`,
      })
        }, [
          _createElementVNode("img", {
            alt: partner,
            src: require(`@/assets/builder/${partner}.png`)
          }, null, 8, _hoisted_1)
        ], 4))
      }), 128))
    ]),
    _: 1
  }, 8, ["id", "isInBuilder", "section"]))
}