<template>
  <div class="playground-placeholder">Drop your components here.</div>
</template>

<style scoped>
.playground-placeholder {
  align-items: center;
  background: #e8f4ff;
  border: 1px solid #5ed2ff;
  border-radius: 5px;
  color: #4095bb;
  display: flex;
  justify-content: center;
  margin: 5px;
  padding: 90px 0;
  width: calc(100% - 12px);
}
</style>
