import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot, withModifiers as _withModifiers, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-4aaf96b5"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["id"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Actions = _resolveComponent("Actions")!
  const _component_Placeholder = _resolveComponent("Placeholder")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["resize-wrapper", { isSelected: _ctx.displayActions }]),
    onClick: _cache[3] || (_cache[3] = 
//@ts-ignore
(...args) => (_ctx.selectComponent && _ctx.selectComponent(...args))),
    id: _ctx.section.id,
    onDragleave: _cache[4] || (_cache[4] = 
//@ts-ignore
(...args) => (_ctx.onDragLeave && _ctx.onDragLeave(...args))),
    onDragover: _cache[5] || (_cache[5] = 
//@ts-ignore
(...args) => (_ctx.onDragOver && _ctx.onDragOver(...args))),
    onMouseover: _cache[6] || (_cache[6] = ($event: any) => (_ctx.hover = true)),
    onMouseleave: _cache[7] || (_cache[7] = ($event: any) => (_ctx.hover = false)),
    ref: "refContainer",
    style: _normalizeStyle({ 'min-height': _ctx.section.attributes?.height + 'px' })
  }, [
    (_ctx.displayActions)
      ? (_openBlock(), _createBlock(_component_Actions, {
          key: 0,
          section: _ctx.section
        }, null, 8, ["section"]))
      : _createCommentVNode("", true),
    _renderSlot(_ctx.$slots, "default", {}, undefined, true),
    (_ctx.isResizable && _ctx.displayActions)
      ? (_openBlock(), _createElementBlock("div", {
          key: 1,
          class: "resize-handler",
          draggable: "true",
          onDrag: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.dragging && _ctx.dragging(...args))),
          onDragend: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.onDragEnd && _ctx.onDragEnd(...args))),
          onDragstart: _cache[2] || (_cache[2] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.onDragStart && _ctx.onDragStart(...args)), ["stop"]))
        }, null, 32))
      : _createCommentVNode("", true),
    (_ctx.draggedOverComponent === _ctx.section.id)
      ? (_openBlock(), _createBlock(_component_Placeholder, { key: 2 }))
      : _createCommentVNode("", true)
  ], 46, _hoisted_1))
}