import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-04ee1567"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "select-number-wrapper" }
const _hoisted_2 = { class: "attribute-name" }
const _hoisted_3 = { class: "attribute-value" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Select = _resolveComponent("Select")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, _toDisplayString(_ctx.settingName) + " :", 1),
    _createElementVNode("div", _hoisted_3, [
      _createVNode(_component_Select, {
        name: _ctx.settingName,
        onChange: _ctx.updateValue,
        options: _ctx.options[_ctx.attribute],
        placeholder: "Select a number",
        value: _ctx.value
      }, null, 8, ["name", "onChange", "options", "value"])
    ])
  ]))
}