
import { defineComponent, PropType } from "vue";

import { ISection } from "@/types";

import { PartnersWrapper } from "./style";

export default defineComponent({
  components: {
    PartnersWrapper,
  },
  props: {
    isInBuilder: {
      default: false,
      type: Boolean,
    },
    section: {
      type: Object as PropType<ISection>,
    },
  },
  computed: {
    partnersArray() {
      if (!this.section?.attributes.itemCount) {
        return null;
      }

      const arrayLength = parseInt(this.section.attributes.itemCount as string);

      return Array.from({ length: arrayLength }, (_, i) =>
        (i + 100).toString()
      );
    },
  },
});
