
import { defineComponent, PropType } from "vue";

import { IconRemove, IconSettings } from "@/components/common";
import { ISection } from "@/types";

export default defineComponent({
  components: {
    IconRemove,
    IconSettings,
  },
  props: {
    section: {
      required: true,
      type: Object as PropType<ISection>,
    },
  },
  methods: {
    handleSelect() {
      this.$store.dispatch("selectComponent", this.section);
    },
    removeComponent() {
      this.$store.dispatch("removeComponent", this.section.id);
    },
  },
});
