
import { defineComponent } from "vue";

export default defineComponent({
  props: {
    color: {
      default: "blue",
      type: String,
    },
    onClick: {
      type: Function,
    },
    text: {
      type: String,
    },
  },
});
