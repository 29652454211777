
import { defineComponent, PropType } from "vue";

import {
  IconDribbble,
  IconInstagram,
  IconLinkedIn,
  IconMail,
  IconWorld,
} from "@/components/common";
import { ISection } from "@/types";

import { ContactWrapper, TextWrapper } from "./style";

export default defineComponent({
  components: {
    ContactWrapper,
    IconDribbble,
    IconInstagram,
    IconLinkedIn,
    IconMail,
    IconWorld,
    TextWrapper,
  },
  props: {
    isInBuilder: {
      default: false,
      type: Boolean,
    },
    section: {
      type: Object as PropType<ISection>,
    },
  },
});
