
import { defineComponent } from "vue";

import {
  IconClean,
  IconMagic,
  IconSave,
  IconSettings,
  MobilePreview,
  Notification,
  ProjectModal,
} from "@/components/common";
import { ISection } from "@/types";
import { saveDocumentInLocalStorage } from "@/utils/localStorage";
import template from "@/utils/predefinedJson";

import { Item, TopBarWrapper } from "./style";

export default defineComponent({
  components: {
    IconClean,
    IconMagic,
    IconSave,
    IconSettings,
    Item,
    MobilePreview,
    Notification,
    ProjectModal,
    TopBarWrapper,
  },
  data() {
    return {
      isMobilePreviewOpened: false,
      isProjectModalOpened: false,
      isSaved: false,
      timer: null as any,
    };
  },
  computed: {
    json(): ISection[] {
      return this.$store.getters.getJson;
    },
  },
  methods: {
    closeProjectModal() {
      this.isProjectModalOpened = false;
    },
    hideMobilePreview() {
      this.isMobilePreviewOpened = false;
    },
    resetJson() {
      this.$store.dispatch("resetJson");
    },
    saveJson() {
      this.startTimer();
      saveDocumentInLocalStorage(this.json);
    },
    showMobilePreview() {
      this.isMobilePreviewOpened = true;
    },
    showProjectModal() {
      this.isProjectModalOpened = true;
    },
    startTimer() {
      if (this.isSaved === true) {
        return;
      }

      this.isSaved = true;

      this.timer = setTimeout(() => {
        this.isSaved = false;
      }, 4000);
    },
    useTemplate() {
      this.$store.dispatch("updateJson", template);
    },
  },
  unmounted() {
    clearTimeout(this.timer);
  },
});
