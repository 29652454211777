import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeStyle as _normalizeStyle, resolveDirective as _resolveDirective, withDirectives as _withDirectives, resolveComponent as _resolveComponent, createVNode as _createVNode, vShow as _vShow, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-9cbdda26"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  class: "attribute-name",
  style: {"margin-bottom":"20px"}
}
const _hoisted_2 = { class: "attribute-value" }
const _hoisted_3 = ["color"]
const _hoisted_4 = { class: "picker-wrapper" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Chrome = _resolveComponent("Chrome")!
  const _directive_click_outside = _resolveDirective("click-outside")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, _toDisplayString(_ctx.settingName) + " : ", 1),
    _createElementVNode("div", _hoisted_2, [
      _withDirectives(_createElementVNode("div", {
        class: "color-circle",
        onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.showPicker && _ctx.showPicker(...args))),
        color: _ctx.selectedComponent.attributes[_ctx.attribute],
        style: _normalizeStyle({
        'background-color': _ctx.selectedComponent.attributes[_ctx.attribute],
      })
      }, null, 12, _hoisted_3), [
        [_directive_click_outside, _ctx.hidePicker]
      ]),
      _withDirectives(_createElementVNode("div", _hoisted_4, [
        _createVNode(_component_Chrome, {
          modelValue: _ctx.color,
          "onUpdate:modelValue": _ctx.updateColor
        }, null, 8, ["modelValue", "onUpdate:modelValue"])
      ], 512), [
        [_vShow, _ctx.isPickerOpened]
      ])
    ])
  ], 64))
}