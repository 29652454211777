export default [
  {
    componentName: "builder-hero",
    componentLabel: "Hero",
    attributes: {
      title: "This is a title for your hero scene",
      subtitle: "This is a subtitle for your hero scene",
      textColor: "white",
      buttonColor: "#b57e6b",
      alignment: { alignItems: "center", justifyContent: "center" },
      resizable: false,
    },
    id: "aaa97f90-d8e8-11ec-ab77-dd4d14bca5fb",
  },
  {
    componentName: "builder-partners",
    componentLabel: "Partners",
    attributes: { itemCount: "5", height: "200", resizable: true },
    id: "dd6f6480-d8e8-11ec-ab77-dd4d14bca5fb",
  },
  {
    componentName: "builder-gallery",
    componentLabel: "Gallery",
    attributes: {
      columns: "4",
      itemCount: "12",
      hasColGap: true,
      colGap: "5",
      hasSectionGap: true,
      resizable: false,
    },
    id: "c86f4370-d8e8-11ec-ab77-dd4d14bca5fb",
  },
  {
    componentName: "builder-text-block",
    componentLabel: "TextBlock",
    attributes: {
      title: "A catchphrase for your section",
      description: "A little text description",
      textColor: "black",
      bgColor: "white",
      alignment: { alignItems: "center", justifyContent: "center" },
      height: "300",
      resizable: true,
    },
    id: "d69e3eb0-d8e8-11ec-ab77-dd4d14bca5fb",
  },
  {
    componentName: "builder-section",
    componentLabel: "Section",
    attributes: {
      title: "A catchphrase for your section",
      description: "A little text description",
      textColor: "#9D34AE",
      bgColor: "#FFFFFF",
      alignment: { alignItems: "center", justifyContent: "center" },
      resizable: false,
      reversed: true,
      hasSectionGap: false,
    },
    id: "e3d67de0-d8e8-11ec-ab77-dd4d14bca5fb",
  },
  {
    componentName: "builder-contact",
    componentLabel: "Contact",
    attributes: {
      title: "Contact",
      description:
        "Here is a section to display your contact infos and tell more your user about the possible inquiries and project you could to together.",
      reversed: false,
      height: "400",
      textColor: "#000000",
      bgColor: "#FFFFFF",
      mail: "estelle.gresillon@gmail.com",
      linkedIn: "https://www.linkedin.com/in/estellegresillon",
      instagram: "https://www.instagram.com/estelle.gresillon",
      dribbble: "https://dribbble.com/estellegresillon",
      other: "estellegresillon.fr",
      resizable: true,
    },
    id: "afbe0140-d8e8-11ec-ab77-dd4d14bca5fb",
  },
];
