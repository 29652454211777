
import { defineComponent } from "vue";

import { saveDataInLocalStorage } from "@/utils/localStorage";

import IconClose from "../Icons/IconClose.vue";
import Input from "../Input/index.vue";
import Select from "../Select/index.vue";

export default defineComponent({
  components: {
    IconClose,
    Input,
    Select,
  },
  props: {
    onClose: {
      type: Function,
    },
  },
  data() {
    return {
      fonts: [
        "Agenda",
        "Austin",
        "Bluu",
        "Circular",
        "Futura",
        "Gilroy",
        "Montserrat",
        "Recife",
      ],
    };
  },
  computed: {
    projectFont() {
      return this.$store.getters.getProjectFont;
    },
    projectName() {
      return this.$store.getters.getProjectName;
    },
    transparentMenu() {
      return this.$store.getters.getTransparentMenu;
    },
  },
  methods: {
    updateFont(e: { target: HTMLInputElement }) {
      this.$store.dispatch("updateProjectFont", e.target.value);
      saveDataInLocalStorage("font", e.target.value);
    },
    updateProjectName(e: { target: HTMLInputElement }) {
      this.$store.dispatch("updateProjectName", e.target.value);
      saveDataInLocalStorage("project", e.target.value);
    },
    updateTransparentMenu() {
      const newMenu = this.transparentMenu === "true" ? "false" : "true";
      this.$store.dispatch("updateTransparentMenu", newMenu);
      saveDataInLocalStorage("transparentMenu", newMenu);
    },
  },
});
