
import { defineComponent, PropType } from "vue";

import { Placeholder } from "@/components/common";
import { ISection } from "@/types";

import Actions from "./Actions.vue";

export default defineComponent({
  components: {
    Actions,
    Placeholder,
  },
  props: {
    section: {
      required: true,
      type: Object as PropType<ISection>,
    },
  },
  data() {
    return {
      hover: false,
      initialPos: null as number | null,
      initialSize: null as number | null,
    };
  },
  computed: {
    displayActions() {
      return this.hover || this.selectedComponent?.id === this.section.id;
    },
    draggedOverComponent() {
      return this.$store.getters.getDraggedOverComponent;
    },
    dragImage() {
      const dragImg = new Image(0, 0);
      dragImg.src =
        "data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7";

      return dragImg;
    },
    isResizable() {
      return this.section.attributes?.resizable;
    },
    selectedComponent() {
      return this.$store.getters.getSelectedComponent;
    },
  },
  methods: {
    dragging(e: { clientY: number }) {
      const resizable = this.$refs.refContainer as HTMLFormElement;

      if (this.initialSize && this.initialPos) {
        resizable.style.height = `${
          this.initialSize + e.clientY - this.initialPos
        }px`;
      }
    },
    onDragEnd() {
      const resizable = this.$refs.refContainer as HTMLFormElement;

      const id = this.section.id;
      const height = resizable.style.height.slice(0, -2);
      this.$store.dispatch("updateAttributes", { id, attributes: { height } });
    },
    onDragLeave() {
      this.$store.dispatch("setDraggedOverComponent", null);
    },
    onDragOver(e: { target: HTMLInputElement }) {
      if (!this.selectedComponent) {
        const selectedItem = e.target;
        this.$store.dispatch("setDraggedOverComponent", selectedItem.id);
      }
    },
    onDragStart(e: { clientY: number; dataTransfer: DataTransfer }) {
      if (!this.dragImage) {
        return;
      }

      e.dataTransfer.setDragImage(this.dragImage, 0, 0);

      e.dataTransfer.dropEffect = "move";
      e.dataTransfer.effectAllowed = "move";
      const resizable = this.$refs.refContainer as HTMLFormElement;

      this.initialPos = e.clientY;
      this.initialSize = resizable.offsetHeight;
    },
    selectComponent() {
      this.$store.dispatch("selectComponent", this.section);
    },
  },
});
