
import { defineComponent } from "vue";

export default defineComponent({
  computed: {
    components() {
      return this.$store.getters.getComponents;
    },
  },
  methods: {
    startDrag(e: { dataTransfer: DataTransfer }, componentLabel: string) {
      e.dataTransfer.dropEffect = "move";
      e.dataTransfer.effectAllowed = "move";
      e.dataTransfer.setData("componentLabel", componentLabel);
    },
  },
});
