import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-34955d70"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  class: "mobile-preview-wrapper",
  id: "mobile-wrapper"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Prod = _resolveComponent("Prod")!

  return (_openBlock(), _createElementBlock("div", {
    class: "overlay",
    onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.onClose && _ctx.onClose(...args)))
  }, [
    _createElementVNode("div", _hoisted_1, [
      _createVNode(_component_Prod)
    ])
  ]))
}