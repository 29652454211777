
import { defineComponent } from "vue";
import { uuid } from "vue-uuid";
import draggable from "vuedraggable";

import { Placeholder, ProdMenu } from "@/components/common";
import initialAttributes from "@/utils/initialAttributes";

import Resize from "./Resize/index.vue";
import Sidebar from "./Sidebar/index.vue";
import TopBar from "./TopBar/index.vue";

export default defineComponent({
  components: {
    draggable,
    Placeholder,
    ProdMenu,
    Resize,
    Sidebar,
    TopBar,
  },
  data() {
    return {
      dragging: false,
      enabled: true,
    };
  },
  computed: {
    draggedOverComponent() {
      return this.$store.getters.getDraggedOverComponent;
    },
    hasSections() {
      return this.$store.getters.getJson.length > 0;
    },
    projectFont() {
      return this.$store.getters.getProjectFont;
    },
    sections() {
      return this.$store.getters.getJson;
    },
    selectedComponent() {
      return this.$store.getters.getSelectedComponent;
    },
  },
  methods: {
    onDragEnd() {
      this.dragging = false;
      this.$store.dispatch("updateJson", this.sections);
    },
    onDrop(e: { target: HTMLInputElement; dataTransfer: DataTransfer }) {
      const componentLabel = e.dataTransfer.getData("componentLabel");

      this.$store.dispatch("setDraggedOverComponent", null);

      if (!componentLabel) {
        return;
      }
      const dropEl = e.target;
      const items = [...this.sections];
      const index = items.findIndex((section) => section.id === dropEl.id);
      const attributes = initialAttributes[componentLabel];

      const newChild = {
        ...attributes,
        componentLabel,
        componentName: attributes.componentName,
        id: uuid.v1(),
      };

      if (index >= 0) {
        items.splice(index + 1, 0, newChild);
      } else {
        items.push(newChild);
      }

      this.$store.dispatch("updateJson", items);
      this.$store.dispatch("selectComponent", newChild);
    },
  },
});
