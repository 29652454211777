import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Divider = _resolveComponent("Divider")!
  const _component_TextBlockContent = _resolveComponent("TextBlockContent")!
  const _component_TextBlockWrapper = _resolveComponent("TextBlockWrapper")!

  return (_openBlock(), _createBlock(_component_TextBlockWrapper, {
    id: _ctx.section.id,
    isInBuilder: _ctx.isInBuilder,
    section: _ctx.section
  }, {
    default: _withCtx(() => [
      _createVNode(_component_TextBlockContent, { section: _ctx.section }, {
        default: _withCtx(() => [
          _createElementVNode("h2", null, _toDisplayString(_ctx.section.attributes.title), 1),
          _createVNode(_component_Divider, {
            color: _ctx.section.attributes.textColor
          }, null, 8, ["color"]),
          _createElementVNode("p", null, _toDisplayString(_ctx.section.attributes.description), 1)
        ]),
        _: 1
      }, 8, ["section"])
    ]),
    _: 1
  }, 8, ["id", "isInBuilder", "section"]))
}