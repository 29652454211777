
import { Chrome } from "@ckpack/vue-color";
import vClickOutside from "click-outside-vue3";
import { defineComponent, PropType } from "vue";

import { Dictionary, ISection } from "@/types";

export default defineComponent({
  components: {
    Chrome,
  },
  props: {
    attribute: {
      required: true,
      type: String,
    },
    selectedComponent: {
      required: true,
      type: Object as PropType<ISection>,
    },
  },
  data() {
    return {
      color: this.selectedComponent.attributes[this.attribute],
      isPickerOpened: false,
      name: {
        bgColor: "BG color",
        buttonColor: "Button color",
        textColor: "Text color",
      } as Dictionary<string>,
    };
  },
  computed: {
    settingName() {
      return this.name[this.attribute];
    },
  },
  methods: {
    hidePicker() {
      this.isPickerOpened = false;
    },
    showPicker() {
      this.isPickerOpened = true;
    },
    updateColor(e: { hex: string }) {
      const newAttr = { [this.attribute]: e.hex };

      this.$store.dispatch("updateAttributes", {
        id: this.selectedComponent.id,
        attributes: newAttr,
      });
    },
  },
  directives: {
    clickOutside: vClickOutside.directive,
  },
});
