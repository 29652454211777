
import { defineComponent } from "vue";

import ComponentList from "./ComponentList/index.vue";
import Settings from "./Settings/index.vue";

export default defineComponent({
  components: {
    ComponentList,
    Settings,
  },
  computed: {
    selectedComponent() {
      return this.$store.getters.getSelectedComponent;
    },
  },
});
