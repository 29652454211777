import { resolveDynamicComponent as _resolveDynamicComponent, openBlock as _openBlock, createBlock as _createBlock, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_view = _resolveComponent("router-view")!
  const _component_ThemeProvider = _resolveComponent("ThemeProvider")!

  return (_openBlock(), _createBlock(_component_ThemeProvider, null, {
    default: _withCtx(() => [
      _createVNode(_component_router_view, null, {
        default: _withCtx((slotProps) => [
          (_openBlock(), _createBlock(_resolveDynamicComponent(slotProps.Component)))
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}