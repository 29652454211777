import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-85808c08"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "sidebar" }
const _hoisted_2 = { key: 0 }
const _hoisted_3 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ComponentList = _resolveComponent("ComponentList")!
  const _component_Settings = _resolveComponent("Settings")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (!_ctx.selectedComponent)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createVNode(_component_ComponentList)
        ]))
      : (_openBlock(), _createElementBlock("div", _hoisted_3, [
          _createVNode(_component_Settings, { selectedComponent: _ctx.selectedComponent }, null, 8, ["selectedComponent"])
        ]))
  ]))
}